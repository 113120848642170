.project {
  position: relative; /* Needed for absolute positioning of pseudo-elements */
  aspect-ratio: 4 / 3;
  padding: 2em 0;
  border-radius: 5px;
  margin: 0 auto;
  text-align: center;
  box-shadow: var(--shadow);
  transition: transform 0.3s linear;
  background-color: transparent;
  overflow: hidden; /* Ensures pseudo-elements do not overflow the container */
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
}

.project::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: var(--project-bg-image); /* Use the custom property */
  background-size: 100% 100%;
  background-position: center;
  transition: opacity 0.3s linear;
  opacity: .8;
}

.project * {
  transition: opacity 0.3s linear; /* Smooth transition for the text */
  opacity: 0; /* Text is hidden by default */
}

.project:hover {
  transform: translateY(2px);
  box-shadow: none;
}

.project:hover::before {
  opacity: .2; /* Fade out the background image on hover */
}

.project:hover *, .project:hover h3, .project:hover p, .project:hover ul, .project:hover a {
  opacity: 1; /* Fade in the text on hover */
}

.project__description {
  margin-top: 1em;
}

.project__stack {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 1.2em 0;
}

.project__stack-item {
  margin: 0.5em;
  font-weight: 500;
  font-size: 0.8rem;
  color: var(--clr-fg-alt);
}

.project .link--icon {
  margin-left: 0.5em;
}
