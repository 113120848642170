.projects__grid {
  max-width: 1100px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.projects__grid > * {
  flex: 0 0 calc(33.33% - 1em);
  min-width: 150px;
  box-sizing: border-box;
}